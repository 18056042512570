import * as React from 'react';
import { navigate } from 'gatsby';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ActiveCampaignFormProps {
  thanksText?: string;
  u: string;
  f: string;
  or: string;
  successCallback?: () => void;
}

/*
Pick one set from these to target correct in ActiveCampaing:
Free ebook:
u: 1
f: 1
or: 5c9b4611fad17e1999bf2c15340f0b47

Get 15 off bits form:
u: 3
f: 3
or: 7435c3bdeedf87497efc568ae1d59977
*/

export default function ActiveCampaignForm( props: ActiveCampaignFormProps = { thanksText: '', u: '1', f: '1', or: '5c9b4611fad17e1999bf2c15340f0b47' }) {
  if (typeof window === 'undefined') {
    return null;
  }

  const { thanksText, successCallback } = props;
  const onSubmit = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.target);

    fetch('https://forestjoker.activehosted.com/proc.php', {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
    .then(() => {

      if (successCallback) {
        successCallback();
      }

      navigate('/thank-you', {
        state: {
          thanksText
        }
      });
    })
    .catch(err => {
      console.log('Request failed', err);
      //TODO: show error
    });
  }

  return (
    <div>
      <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3 }}>
        <input type="hidden" name="u" value={props.u} />
        <input type="hidden" name="f" value={props.f} />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <input type="hidden" name="or" value={props.or} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstname"
              required
              fullWidth
              id="firstname"
              label="First Name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastname"
              label="Last Name"
              name="lastname"
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </Button>
        <Typography variant="body2">
        By signing up you agree to receive future email marketing communication from Ultra Handy
        </Typography>
      </Box>
    </div>
  )

}